import React, { useState, useEffect } from "react";
// import Pagination from "react-js-pagination";
import Product from "../../product";
import "./style.scss";

const Products = (props) => {
  const { products } = props;
  const [activePage, setActivePage] = useState(1);
  const [currentProducts, setCurrentProducts] = useState(null);
  const productsPerPage = 6;
  // const pageRange = 10;

  /*
  helper function to log data
  products.forEach((product, i) => {
      console.log(product)
  });
  */

  /**
   * Whenever the products length changes,
   * which means the Product component is re-rendered because
   * its parent changed the 'products' value ( because search was done, by user, so new product results ),
   * then do the following :
   * 1. Set the active page to 1 ( refresh current page to 1 ),
   * 2. Update the products to be displayed
   *
   */
  useEffect(() => {
    const activePage = 1;
    setActivePage(activePage);

    /* eslint-disable */
    setProductsToBeDisplayed(
      activePage * productsPerPage
    ); /* eslint-disable no-alert, no-console */
  }, [products.length]);

  useEffect(() => {
    /* eslint-disable */
    setProductsToBeDisplayed(activePage * productsPerPage);
  }, [activePage]);

  /**
   * Logic for displaying current products
   *
   * @param lastProductIndex Last product index.
   */
  const setProductsToBeDisplayed = (lastProductIndex) => {
    const indexOfLastProduct = lastProductIndex; // e.g. 6
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage; // e.g. 6-6 = 0 ( when product per page is 6 )

    // Get all the products from index of first product, to index of last product
    const currentProductsData = products.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    ); // e.g. products from index 0 to 6 ( 6 items ).
    setCurrentProducts(currentProductsData);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  if (null === currentProducts) {
    return null;
  }

  return (
    <>
      {currentProducts.filter(
        (product) =>
          product.productCategories.nodes[0].name === "Functional drinks"
      ).length > 0 ? (
        <>
          <div
            id="mushroom-drinks"
            style={{
              width: "100%",
              backgroundColor: "#FCFAF6",
              padding: "60px 15px 15px 15px",
              textAlign: "center",
            }}
          >
            <h2
              className="display-8"
              style={{ padding: "10px", letterSpacing: "2px" }}
            >
              Grab Your <span className="spec-h">shroom</span> drinks now!
            </h2>
          </div>
          <div
            className="container"
            style={{
              backgroundColor: "#FCFAF6",
              margin: "0",
              minWidth: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              className="product-container row py-5"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {currentProducts
                .filter(
                  (product) =>
                    product.productCategories.nodes[0].name ===
                    "Functional drinks"
                )
                .map((product) => (
                  <Product key={product.id} product={product} />
                ))}
            </div>
            {/*<Pagination
        activePage={activePage}
        itemsCountPerPage={productsPerPage}
        totalItemsCount={products.length}
        pageRangeDisplayed={pageRange}
        onChange={handlePageChange}
        itemClass={"page-item"}
        linkClass={"page-link"}
        prevPageText={"Previous"}
        nextPageText={"Next"}
        hideDisabled={true}
      />*/}
          </div>
        </>
      ) : null}

      {currentProducts.filter(
        (product) => product.productCategories.nodes[0].name === "Shroom Livin'"
      ).length > 0 ? (
        <>
          <div
            id="accessories"
            style={{
              width: "100%",
              backgroundColor: "#FCFAF6",
              padding: "60px 15px 15px 15px",
              textAlign: "center",
            }}
          >
            <h2 className="spec-h display-8" style={{ padding: "10px" }}>
              Acessories
            </h2>
            <p>Original clothing and accessories, the official shroom merch.</p>
          </div>
          <div
            className="container"
            id="accessories-row"
            style={{
              backgroundColor: "#FCFAF6",
              margin: "0",
              minWidth: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              className="product-container row py-5"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {currentProducts
                .filter(
                  (product) =>
                    product.productCategories.nodes[0].name === "Shroom Livin'"
                )
                .map((product) => (
                  <Product key={product.id} product={product} />
                ))}
            </div>
          </div>
        </>
      ) : null}
      <div
        style={{
          width: "100%",
          backgroundColor: "#FCFAF6",
          padding: "15px 15px 60px 15px",
          textAlign: "center",
        }}
      >
        <h2
          className="display-8"
          style={{ padding: "10px", letterSpacing: "2px" }}
        >
          feel better with every sip
        </h2>
      </div>
    </>
  );
};

export default Products;
